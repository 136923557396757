import styles from "./terms.module.css"
const TermsSoftware = () => {
    return(
        <div className={styles.container}>
            <h2 className='block-title'>СРОКИ И СТОИМОСТЬ</h2>
            <h3>Стоимость проекта и сроки рассчитываются индивидуально под каждый проект</h3>
            <div className={styles.table}>
                <div>
                    <div>Тип проекта</div>
                    <div>Программное обеспечение</div>
                </div>
                <div>
                    <div>Срок работы</div>
                    <div>От 3-ех месяцев</div>
                </div>
                <div>
                    <div>Стоимость</div>
                    <div>От 2,5 млн. рублей</div>
                </div>
            </div>
        </div>
    )
}
export default TermsSoftware