import styles from './paymentformats.module.css'
import block from '../../../common/commonblock1.module.css'

const PaymentFormats = () => {
	
	const prosCons = [
		{
			id: 1,
			name: 'Стоимость работ',
			prosIcon: '/Pictures/services/fix-price.svg ',
			conIcon: '/Pictures/services/time-mat.svg',
			prosText: 'Управляемый бюджет. Оплата фактического времени работ',
			conText: 'Фиксированный бюджет, но есть вероятность, что объем работ может стать меньше'
		},
		{
			id: 2,
			name: 'Прогноз стоимости',
			prosIcon: '/Pictures/services/+.svg',
			conIcon: '/Pictures/services/-.svg',
			prosText: 'Приблизительный прогноз бюджета',
			conText: 'Фиксированный бюджет конкретного объема работ'
		},
		{
			id: 3,
			name: 'Правки и корректировки требований',
			prosIcon: '/Pictures/services/-.svg ',
			conIcon: '/Pictures/services/+.svg',
			prosText: 'Возможность уточнять и изменять требования и объем работ',
			conText: 'Требования зафиксированы для этапа работ. Для новых требований понадобится дополнительное соглашение'
		},
		{
			id: 4,
			name: 'Транссакционные издержки (расходы по сделкам)',
			prosIcon: '/Pictures/services/-.svg',
			conIcon: '/Pictures/services/+.svg',
			prosText: 'Постоянная синхронизация приоритетных задач',
			conText: 'Для любых новых требований необходимо дополнительное соглашение'
		},
		{
			id: 5,
			name: 'Скорость получения результата',
			prosIcon: '/Pictures/services/time-mat.svg',
			conIcon: '/Pictures/services/rocket.svg',
			prosText: 'МВП можно запустить в ближайший срок',
			conText: 'Проект будет выведен на рынок только после завершения финального этапа'
		},
		{
			id: 6,
			name: 'Возможность остановить проект',
			prosIcon: '/Pictures/services/-.svg',
			conIcon: '/Pictures/services/+.svg',
			prosText: 'В любой момент можете забрать задокументированный проект с разработанным функционалом',
			conText: 'Продукт передается только после завершения финального этапа'
		},
		{
			id: 7,
			name: 'Время на внесение правок',
			prosIcon: '/Pictures/services/-.svg',
			conIcon: '/Pictures/services/+.svg',
			prosText: 'В каждом спринте выделяем время на внесение правок',
			conText: 'Для внесения правок нужно дополнительное соглашение'
		}
	]
	
	return (
		<div className={styles.container}>
			<h3>ФОРМАТЫ ОПЛАТЫ</h3>
			<h4>Стоимость работ</h4>
			<div className={block.container}>
				<img src='/Pictures/services/bell.svg'/>
				<div>
					<p>Time & Material (время и материалы) и Fix Price — это две разные модели оплаты в разработке
						веб-проектов</p>
				</div>
			</div>
			<div className={styles.about}>
				<img src='/Pictures/services/time-mat.svg' alt='' />
				<p>Time & Material — это модель, при которой заказчик оплачивает агентству время
					работы специалистов, а не фиксированную сумму за весь проект. Оценка объёма работ и сроков производится
					приблизительно, а главным является результат и качество
				</p>
			</div>
			<div className={styles.about}>
				<img src='/Pictures/services/fix-price.svg' alt='' />
				<p>Fix Price — это модель, при которой заказчик оплачивает агентству определённую
					сумму за весь проект по заранее оговорённой стоимости и сроках выполнения работы. Оценка объёма работ и сроков
					производится точно, и главным приоритетом является соблюдение сроков и выделенного бюджета
				</p>
			</div>
			<h3 className={styles.prosConsTitle}>Плюсы и минусы форматов оплаты</h3>
			<table>
				<thead>
					<tr>
						<th> </th>
						<th>Time & Material</th>
						<th>Fix Price</th>
					</tr>
				</thead>
				<tbody>
				{prosCons.map((item, index) => (
					<tr>
						<td>{item.name}</td>
						<td>
							<img alt='' src={item.conIcon}/>
							<p>{item.prosText}</p>
						</td>
						<td>
							<img alt='' src={item.prosIcon} />
							<p>{item.conText}</p>
						</td>
					</tr>
				))}
				</tbody>
			</table>
		</div>
	)
}
export default PaymentFormats