import React, {useEffect} from "react";
import { useLocation } from 'react-router-dom';
import CaseComponent from '../components/case/Case'
import TitleMain from "../components/title/TitleMain";
import Partners from "../components/partners/Partners";
import Services from "../components/services/Services";
import Values from "../components/values/Values";
import Questions from "../components/questions/Questions";
import Contacts from "../components/contacts/Contacts";
import Footer from "../components/footer/Footer";
import Vacancies from "../components/vacancies/Vacancies";
import Advantages from "../components/advantages/Advantages";
import cases, { Case } from '../mock/cases'

const MainPage = () => {
    const location = useLocation();
    useEffect(() => {
        if (location.state?.scrollToVacancies) {
            // Если передано состояние, скроллим к блоку с id "vacancies"
            const block = document.getElementById("vacancies");
            if (block) {
                const elementPosition = block.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - 100; // Отступ сверху
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [location]);
    return(
      <>
        <TitleMain />
        <Partners />
        <Services />
        <h2 className='block-title' id="Cases">Кейсы</h2>
        <CaseComponent _case={cases[Case.Prokrasivoe]} />
        <CaseComponent _case={cases[Case.Gradient]} />
        <CaseComponent _case={cases[Case.SKUD]} />
        <Vacancies />
        <Advantages />
        <Questions />
        <Contacts />
        <Footer />
      </>
    )
}
export default MainPage