import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import styles from '../../common/popup.module.css'
import { Vacancy } from '../../types/types'
import { FilesSchema, VacancySchema } from '../../validation/schemas'

const VacanciesPopup = (props: {
	open: boolean,
	setOpen: Dispatch<SetStateAction<boolean>>,
	selectedVacancy: string | null
}) => {
	const token = process.env.REACT_APP_TELEGRAM_TOKEN
	const { open, setOpen, selectedVacancy } = props
	const [active, setActive] = useState(false)
	const [selectedFiles, setSelectedFiles] = useState<File[]>([])
	
	const
		{
			register,
			handleSubmit,
			formState: { errors, isSubmitting },
			reset,
			setError,
		} = useForm<Vacancy>({
			resolver: zodResolver(VacancySchema),
			defaultValues: { phone: "" },
		})
	const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if ((event.target as HTMLDivElement).className === styles.container) {
			handleModalClose()
		}
	}
	const onSubmit: SubmitHandler<Vacancy> = async (data: Vacancy) => {
		try {
			await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
				chat_id: '-4227583515',
				text: `👨 ‍Отклик на вакансию '${selectedVacancy}': \n
				Имя: ${data.name}\n
				Телефон: ${data.phone}\n
				Имя telegram: @${data.username}`
			})
			for (let i = 0; i < selectedFiles.length; i++) {
				const fileData = new FormData()
				fileData.append('chat_id', '-4227583515')
				fileData.append('document', selectedFiles[i])
				await axios.post(`https://api.telegram.org/bot${token}/sendDocument`, fileData)
			}
			alert('Ваш отклик был успешно отправлен!')
			reset()
			setActive(false)
			setSelectedFiles([]);
			handleModalClose()
		} catch (error) {
			alert('Ошибка при отправке отклика. Попробуйте еще раз.')
		}
	}
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const files = Array.from(e.target.files)
			setSelectedFiles(files)
			const result = FilesSchema.safeParse(e.target.files)
			const message = result.error?.errors[0]?.message
			setError("files", {type: "invalid", message: message})
		}
	}
	if (open) {
		document.body.classList.add("no-scroll")
	}
	const handleModalClose = () => {
		setOpen(false);
		document.body.classList.remove("no-scroll");
	}
	return (
		<>
			{open &&
				<div className={styles.container} onClick={handleModalClick}>
					<div className={styles.content}>
						<img src='/Pictures/popup-close.png' className={styles.close} onClick={handleModalClose}/>
						<h1>Отклик на вакансию '{selectedVacancy}'</h1>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div>
								<input {...register('name')} type='text' placeholder='Ваше имя'/> {errors.name &&
								<span className={styles.errorMessage}>{errors.name.message}</span>}
							</div>
							<div>
								<InputMask {...register('phone')} mask='+7 (999) 999-99-99' placeholder='+7 (000) 000-00-00'
								/> {errors.phone &&
								<span className={styles.errorMessage}>{errors.phone.message}</span>}
							</div>
							<div>
								<input {...register('username')} type='text' placeholder='Ваш Telegram username (без @)'
								/> {errors.username &&
								<span className={styles.errorMessage}>{errors.username.message}</span>}
							</div>
							<div className={styles.fileInputWrapper}>
								<input
									type='file' className={styles.fileInput} id='file-input'										{...register('files')}
									multiple onChange={handleFileChange}
								/> <label htmlFor='file-input' className={styles.fileInputLabel}>
								<img src='/Pictures/papersclip.svg'/>
								<span>Прикрепить файл</span> </label>
								<div className={styles.selectedFiles}>
									{selectedFiles.map((file, index) => (
										<div key={index} className={styles.fileName}>{file.name}</div>
									))}
								</div>
								{errors.files && <span className={styles.errorMessage}>{errors.files.message}</span>}
								<p>**Максимум 3 файла (.jpg, .png, .doc, .xls, .pdf), не более 6 МБ каждый</p>
							</div>
							<button
								type='submit' className={active && !isSubmitting ? 'blueButton' : ''}
								disabled={!(active && !isSubmitting)}
							>{isSubmitting ? 'Загрузка...' : 'Отправить'}</button>
							<div className={styles.confidential}>
								<p>Согласен на обработку своих персональных данных в соответствии с <span
									onClick={() => {
										window.open('https://вр-ит.рф/ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf')
									}}
								>Политикой
									конфиденциальности</span>. </p>
								<label className={styles.fancyCheckbox}> <input
									type='checkbox' className={styles.c} {...register('agree')} onClick={() => {
									setActive(!active)
								}}
								/>
									<div>
										<div>
											<img src='/Pictures/checkmark.png'/>
										</div>
									</div>
								</label>
							</div>
						</form>
					</div>
				</div>
			}
		</>
	)
}

export default VacanciesPopup