
import { useNavigate } from 'react-router-dom'
import styles from "./weareresidents.module.css"
const WeAreResidents = () => {
  const navigate = useNavigate()
    return(
        <div className={styles.container}>
            <h3>МЫ ЯВЛЯЕМСЯ РЕЗИДЕНТАМИ ТЕХНОПАРКА</h3>
            <p>Нажмите на кнопку, чтобы увидеть доказательство</p>
            <button onClick={() => {window.open("https://morion.digital/residents/vr/")}}>Открыть</button>
        </div>
    )
}
export default WeAreResidents