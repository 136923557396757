import React, { useState, useRef, useEffect } from "react";
import styles from "./advantages.module.css";
import advantages from "../../mock/advantages";

const Advantages = () => {
    const [visibleAdvantage, setVisibleAdvantage] = useState<number | null>(null);
    const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

    const toggleAdvantage = (id: number) => {
        if (visibleAdvantage === id) {
            setVisibleAdvantage(null);
        } else {
            setVisibleAdvantage(id);
        }
    };

    useEffect(() => {
        // Анимируем изменение высоты
        contentRefs.current.forEach((content, index) => {
            if (content) {
                if (visibleAdvantage === index) {
                    // Устанавливаем maxHeight в высоту контента
                    content.style.maxHeight = `${content.scrollHeight}px`;
                } else {
                    // Скрываем контент
                    content.style.maxHeight = '0';
                }
            }
        });
    }, [visibleAdvantage]);

    return (
        <div>
            <h2 className='block-title'>ПРЕИМУЩЕСТВА</h2>
            <div className={styles.container}>
                {advantages.map((advantage, index) => (
                    <div className={styles.item} key={advantage.id}>
                        <div className={styles.head} onClick={() => toggleAdvantage(index)}>
                            <h3>{advantage.name}</h3>
                            <div className={styles.button}>
                                {visibleAdvantage === index ? '-' : '+'}
                            </div>
                        </div>
                        <p
                            ref={(el) => (contentRefs.current[index] = el)}
                        >
                            {advantage.text}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Advantages;
