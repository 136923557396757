const advantages = [
    {
        id: 1,
        name: 'Прозрачность и открытость процессов',
        text: 'Открыто рассказываем из чего складывается, как устроено производство, какие у проекта имеются риски и всегда держим клиента в курсе дела.'
    },
    {
        id: 2,
        name: 'Глубокая аналитика проекта',
        text: 'Проводим целевой анализ аудитории, рынка и конкурентов перед началом разработки, чтобы полностью изучить программную часть и создать отличный продукт.'
    },
    {
        id: 3,
        name: 'Богатый опыт',
        text: 'За 4 года работы выработали собственный алгоритм внутреннего взаимодействия для максимально эффективной разработки в срок. Для этого мы используем современные подходы «Scrum», работаем с использованием CI/CD, а также используем собственные готовые модули и конструкции, которые позволяют выполнять работу быстрее чем конкуренты. Также, большинство наших разработчиков имеют опыт работы более 15-ти лет и разрабатывают крупные проекты для корпоративных клиентов.'
    },
    {
        id: 4,
        name: 'Сотрудничаем с правительством и крупными компаниями',
        text: 'Не боимся брать ответственность за крупные проекты с фиксированным бюджетом и сроками. Сотрудничаем с крупными компаниями и университетами Урала.'
    },
]
export default advantages