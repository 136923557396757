import Contacts from '../../components/contacts/Contacts'
import Footer from '../../components/footer/Footer'
import Questions from '../../components/questions/Questions'
import styles from './products-page.module.scss'
import advantages from '../../common/commonblock2.module.css'
import block from '../../common/commonblock1.module.css'

const OfferGen = () => {
	return (
		<>
			<div className={styles.container}>
				<h1>Генератор сметы</h1>
				<img src='/Pictures/products/offergen/ui1.png' alt='' />
				<h2 className={styles.centered}>Что такое генератор сметы?</h2>
				<div className={block.container}>
					<img src='/Pictures/services/bell.svg' alt='' />
					<div>
						<p>Генератор сметы — это ваш незаменимый помощник для создания профессиональных, точных и быстрых
							расчетов! {'\n\n'}Приложение позволяет всего за несколько кликов подготовить полные сметы для клиентов,
							будь то индивидуальный предприниматель или крупная компания. С его помощью вы сможете легко рассчитать
							стоимость работ за услугу с учетом всех этапов и сроков.{'\n\n'}Итоговый документ оформляется в Excel и
							наглядно демонстрирует разбивку по задачам, этапам и срокам, что повышает доверие клиентов и подчеркивает
							ваш профессионализм.</p>
					</div>
				</div>
				<button className='blueButton' onClick={() => {
					window.open('https://вр-кп.рф')
				}}>Попробовать</button>
				<h3>Как работает генератор сметы?</h3>
				<p>По каждой задаче пользователь вводит название, количество дней/недель на ее выполнение. Из количества
					дней/недель получается количество часов, которое умножается на тариф (стоимость одного часа) и получается
					стоимость задачи.</p>
				<p>По этапу из суммы дней/недель на его задачи и суммы стоимостей получается общее кол-во дней/недель, часов,
					общая стоимость. Также суммируется общая сумма проекта, общее кол-во часов, общее кол-во дней/недель.</p>
				<img src='/Pictures/products/offergen/ui2.png' alt='' />
			</div>
			<div className={advantages.container}>
				<h2 className='block-title'>Преимущества</h2>
				<section>
					<div className={advantages.checkmark}>
						<div></div>
						<img src='/Pictures/phone-heart.png' alt='' /></div>
					<div className={advantages.textContainer}>
						<h3>Удобство пользования</h3>
						<p>Легкое и быстрое создание сметы с возможнностью выгрузки excel-файла</p>
					</div>
				</section>
				<section>
					<div className={advantages.checkmark}>
						<div></div>
						<img src='/Pictures/services/time-mat-lg.svg' alt='' /></div>
					<div className={advantages.textContainer}>
						<h3>Возможность вернуться к работе спустя время</h3>
						<p>Если вы закрыли вкладку, или полностью браузер, то можете потом вновь открыть ссылку и вся таблица будет
							сохранена</p>
					</div>
				</section>
				<section>
					<div className={advantages.checkmark}>
						<div></div>
						<img src='/Pictures/lock.png' alt='' /></div>
					<div className={advantages.textContainer}>
						<h3>Полная конфиденциальность</h3>
						<p>Программа не собирает и не хранит введенные данные. Вся информация, которую вводит пользователь,
							сохраняется лишь локально в браузере на его ПК</p>
					</div>
				</section>
			</div>
			<div className={styles.container}>
				<h2 className='block-title'>Фото генератора сметы</h2>
				<div className={styles.photos}>
					<img src='/Pictures/products/offergen/ui3.png' alt='' /> <img
					src='/Pictures/products/offergen/ui4.png'
					alt='' /> <img src='/Pictures/products/offergen/excel.png' alt='' />
				</div>
				<button className='blueButton' onClick={() => {
					window.open('https://вр-кп.рф')
				}}>Начать использовать</button>
			</div>
			<Questions /> <Contacts /> <Footer />
		</>
	)
}

export default OfferGen