import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import styles from '../../common/popup.module.css'
import type { Callback } from '../../types/types'
import { CallbackSchema } from '../../validation/schemas'

const CallbackPopup = (props: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>> }) => {
	const token = process.env.REACT_APP_TELEGRAM_TOKEN
	const { open, setOpen } = props
	const {
		register,
		handleSubmit,
		formState: {
			errors,
			isSubmitting
		},
		reset
	} = useForm<Callback>({
		resolver: zodResolver(CallbackSchema),
		defaultValues: { phone: "" },
	})
	const [active, setActive] = useState(false)
	const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if ((event.target as HTMLDivElement).className === styles.container) {
			handleModalClose()
		}
	}
	const OnSubmit: SubmitHandler<Callback> = async (data: Callback) => {
		try {
			await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
				chat_id: '-4227583515',
				text: `💻 Заявка на обратный звонок:\n
				Имя: ${data.name}\n
				Телефон: ${data.phone}\n`
			})
			reset()
			setActive(false)
			alert('Заявка успешно отправлена!')
			handleModalClose()
		} catch (error) {
			console.error('Ошибка отправки:', error)
			alert('Ошибка отправки сообщения!')
		}
	}
	if (open) {
		document.body.classList.add("no-scroll")
	}
	const handleModalClose = () => {
		setOpen(false);
		document.body.classList.remove("no-scroll");
	}
	return (
		<>
			{open &&
				<div className={styles.container} onClick={handleModalClick}>
					<div className={styles.content}>
						<img src='/Pictures/popup-close.png' className={styles.close} onClick={handleModalClose}/>
						<h1>Заявка на обратный звонок</h1>
						<form onSubmit={handleSubmit(OnSubmit)}>
							<div>
								<input {...register('name')} type='text' placeholder='Ваше имя'/> {errors.name &&
								<span className={styles.errorMessage}>{errors.name.message}</span>}
							</div>
							<div>
								<InputMask {...register('phone')} mask='+7 (999) 999-99-99' placeholder='+7 (000) 000-00-00'
								/> {errors.phone && <span className={styles.errorMessage}>{errors.phone.message}</span>}
							</div>
							<button
								type='submit' className={active && !isSubmitting ? 'blueButton' : ''} disabled={!active || isSubmitting}
							>{isSubmitting ? 'Загрузка...' : 'Отправить'}</button>
							<div className={styles.confidential}>
								<p>Согласен на обработку своих персональных данных в соответствии с <span
									onClick={() => {
										window.open('https://вр-ит.рф/ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf')
									}}
								>Политикой конфиденциальности</span>. </p>
								<label className={styles.fancyCheckbox}> <input {...register('agree', { required: true })}
									type='checkbox' className={styles.c} onClick={() => {
									setActive(!active)
								}}
								/>
									<div>
										<div>
											<img src='/Pictures/checkmark.png'/>
										</div>
									</div>
								</label>
							</div>
						</form>
					</div>
				</div>
			}
		</>
	)
}

export default CallbackPopup