import styles from '../../../common/commonblock2.module.css'

const Principles = () => {
	return (
		<div className={styles.container}>
			<h2 className='block-title'>ПРИНЦИПЫ РАБОТЫ</h2>
			<section>
				<div className={styles.checkmark}>
					<div></div>
					<img src='/Pictures/services/principle1.svg' />
				</div>
				<div className={styles.textContainer}>
					<h3>Автоматизируем и оптимизируем бизнес-процессы</h3>
					<p>Автоматизация помогает бизнесу кратно расти, а также собирать необходимые данные для прогнозирования
						развития бизнеса</p>
				</div>
			</section>
			<section>
				<div className={styles.checkmark}>
					<div></div>
					<img src='/Pictures/services/principle2.svg' />
				</div>
				<div className={styles.textContainer}>
					<h3>Доверяем аналитике и пользовательскому опыту</h3>
					<p>Анализируем пользовательский опыт людей, который помогает избежать множества вопросов и ошибок</p>
				</div>
			</section>
			<section>
				<div className={styles.checkmark}>
					<div></div>
					<img src='/Pictures/services/principle3.svg' />
				</div>
				<div className={styles.textContainer}>
					<h3>Используем гибкую структуру разработки и собственную систему для ускорения разработки</h3>
					<p>С помощью собственной системы «Atom» можем быстро собрать работающий МВП и выпустить на рынок</p>
				</div>
			</section>
		</div>
	)
}
export default Principles