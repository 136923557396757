import {ReactNode} from "react";
import styles from "./probsol.module.css"

export enum PSType {
    Problem,
    Solution
}

const ProbSol = (props: { type: PSType; children: ReactNode; }) => {
    const {type, children} = props;
    let headerText: string;
    let imagePath: string = "";
    switch (type) {
        case PSType.Problem:
            headerText = "ЗАДАЧА:"
            imagePath = "/Pictures/services/bell.svg"
            break;
        case PSType.Solution:
            headerText = "РЕШЕНИЕ:"
            imagePath = "/Pictures/services/checkmark.svg"
            break;
    }
    return(
        <div className={styles.container}>
            <h2 className={styles.header}>{headerText}</h2>
            <div className={styles.block}>
                <img src={imagePath} alt="Колокольчик" className={styles.img}/>
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ProbSol;