import styles from "./companyinfo.module.css"
const CompanyInfo = () => {
    return(
        <div className={styles.container}>
            <h2 className="block-title">О КОМПАНИИ</h2>
            <section className={styles.left}>
                <div>
                    <h3>Наша миссия</h3>
                    <p>
                        Обеспечить доступ к качественному, надежному и значимому программному обеспечению для граждан
                        РФ. Мы стремимся к созданию продуктов, которые упрощают жизнь людей, делают ее более комфортной и безопасной.
                    </p>
                </div>
                <img src="/Pictures/about-company/img1.png"/>
            </section>
            <section className={styles.right}>
                <img src="/Pictures/about-company/img2.png"/>
                <div>
                    <h3>Наша цель</h3>
                    <p>
                        Мы ставим перед собой цель стать лидерами в сфере разработки отечественного ПО,
                        обеспечивая независимость нашей страны от иностранных IT-решений.
                    </p>
                </div>
            </section>
            <section className={styles.middle}>
                <div>
                    <h3>Наше мышление</h3>
                    <p>
                        Компания ВР — это не просто разработчик программного обеспечения, это образ мышления, который
                        позволяет видеть перспективы в любой проблеме и экспериментировать с разными подходами. Мы
                        уверены, что всё вокруг создано людьми, которые не умнее нас, и это мотивирует нас к постоянным
                        инновациям и изобретениям.
                    </p>
                    <p>
                        Наша команда состоит из любознательных и уверенных в себе специалистов, которые готовы
                        разбираться в сложных вопросах и находить нестандартные решения. Мы смотрим на мир с другой
                        стороны и видим возможности там, где другие видят только проблемы.
                    </p>
                </div>
                <img src="/Pictures/about-company/img3.png"/>
                <p>ВР помогает своим клиентам и партнёрам преодолевать трудности и достигать успеха, используя передовые
                    технологии и инновационные подходы. Мы стремимся быть лидерами в своей отрасли и постоянно
                    совершенствуем свои продукты и услуги, чтобы соответствовать потребностям рынка.</p>
                <p>ВР — это образ мышления!</p>
            </section>
        </div>
    )
}
export default CompanyInfo