import styles from './forwhatmvp.module.css'
import block from '../../../common/commonblock1.module.css'

const ForWhatMvp = () => {
	return (
		<>
			<h2 className='block-title'>ДЛЯ ЧЕГО НУЖЕН MVP</h2>
			<div className={styles.arguments}>
				<div>
					<img src='/Pictures/services/arg-icon1.svg' />
					<p>Получить объективную обратную связь от пользователей и понять,
						что нужно еще внедрить, а что убрать</p>
				</div>
				<div>
					<img src='/Pictures/services/arg-icon2.svg' />
					<p>Снизить временные и финансовые затраты, а также, риски, что пользователям не понравится продукт</p>
				</div>
				<div>
					<img src='/Pictures/services/arg-icon3.svg' />
					<p>Быстро выйти на рынок с самым главным функционалом. Весь последующий функционал разрабатывать в
						процессе</p>
				</div>
			</div>
			<div className={styles.conclusion}>
				<h3>ИТОГ</h3>
				<div className={block.container}>
					<img className='bell' src='/Pictures/services/bell.svg' />
					<div>
						<p>Если начать разработку с МВП можно хорошо сэкономить временные и денежные затраты, за счет обратной связи
							от пользователей. Пользователи сами расскажут, какие именно функции им нужны, а какие нет. Поэтому, вы не
							будете тратить лишний бюджет и время на разработку ненужного функционала</p>
					</div>
				</div>
			</div>
		</>
	)
}
export default ForWhatMvp