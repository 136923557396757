import styles from "../../../common/commonblock2.module.css"
const WhenNeedWeb = () => {
    return(
        <div className={styles.container}>
            <h2 className='block-title'>КОГДА НУЖЕН ВЕБ‑СЕРВИС</h2>
            <section>
                <div className={styles.checkmark}>
                    <div></div><img src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className={styles.textContainer}>
                    <h3>Необходимость автоматизации бизнес-процессов</h3>
                    <p>Веб-сервисы помогают автоматизировать различные аспекты деятельности компании, такие как обработка заказов, учёт финансов, управление персоналом и другие.</p>
                </div>
            </section>
            <section>
                <div className={styles.checkmark}>
                    <div></div><img src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className={styles.textContainer}>
                    <h3>Ускорение взаимодействия между сотрудниками и партнёрами</h3>
                    <p>Веб-сервисы предоставляют инструменты для обмена информацией, совместной работы над проектами и оперативного решения возникающих вопросов.</p>
                </div>
            </section>
            <section>
                <div className={styles.checkmark}>
                    <div></div><img src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className={styles.textContainer}>
                    <h3>Повышение эффективности работы</h3>
                    <p>Веб-сервисы позволяют оптимизировать рабочие процессы, сокращать время на выполнение задач и снижать издержки.</p>
                </div>
            </section>
            <section>
                <div className={styles.checkmark}>
                    <div></div><img src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className={styles.textContainer}>
                    <h3>Удовлетворение потребностей клиентов</h3>
                    <p>Веб-сервисы могут использоваться для создания удобных интерфейсов для заказа товаров и услуг, обратной связи с клиентами и обработки их запросов.</p>
                </div>
            </section>
            <section>
                <div className={styles.checkmark}>
                    <div></div><img src='/Pictures/services/checkmark.svg'/>
                </div>
                <div className={styles.textContainer}>
                    <h3>Развитие бизнеса</h3>
                    <p>Веб-сервисы помогают расширять географию присутствия компании, увеличивать количество клиентов и партнёров, а также повышать конкурентоспособность на рынке.</p>
                </div>
            </section>
        </div>
    )
}
export default WhenNeedWeb