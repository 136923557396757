import React, { useContext, useState } from 'react'
import { QuestionsContext } from '../../App'
import { useNavigateTo } from '../../hooks/useNavigateTo'
import services from "../../mock/services";
import styles from "./services.module.css"
const Services = () => {
    const navigateTo = useNavigateTo()
    const setQuestionsModalVisible = useContext(QuestionsContext)
    const scrollTo = (elementId: string): boolean => {
        const block = document.getElementById(elementId);
        if (block) {
            const elementPosition = block.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - 100;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
            return true
        }
        return false
    };
    const handleQuestionsClick = () => {
        if(!scrollTo('questions')) {
            setQuestionsModalVisible(true)
        }
    }
    return(
        <div className={styles.container}>
            <h2 className="block-title">УСЛУГИ</h2>
            <div className={styles.services}>
                {services.map(service => (
                    <div>
                        <p className={styles.logo}>ВР</p>
                        <p className={styles.serviceName}>{service?.name}</p>
                        <div className={`${styles.cardOrder} blueButton`} onClick={() => { navigateTo(service.link) }}>Подробнее</div>
                        <p className={styles.hashtag}>#ВЕБРАЗРАБОТКА</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Services;