import { z } from 'zod'


const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/msword', 'application/vnd.ms-excel', 'application/pdf']
const MAX_FILES_AMOUNT = 3;
const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6 MB

// Проходит по списку файлов и находит индекс первого файла с неверным типом
const firstTypeMismatch = (files: FileList) : number => {
    for (let i= 0; i < files.length; i++)
        if (!ACCEPTED_FILE_TYPES.includes(files[i].type))
            return i
    return -1
}

const firstSizeMismatch = (files: FileList) : number => {
    for (let i= 0; i < files.length; i++)
        if (files[i].size > MAX_FILE_SIZE)
            return i
    return -1
}

const NameSchema = z.string()
    .min(1, "Введите имя")
const PhoneSchema = z.string()
    .regex(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, "Введите номер телефона в формате +7 (123) 456-78-90")
export const FilesSchema = z.instanceof(FileList)
  .refine((files: FileList) => files.length <= MAX_FILES_AMOUNT, `Файлов должно быть ${MAX_FILES_AMOUNT} или меньше`)
  .refine((files: FileList) => { return firstTypeMismatch(files) === -1; },
    (files: FileList) => ({
        message: `${firstTypeMismatch(files) > -1 ? files[firstTypeMismatch(files)].name : ""} неверного типа`
    }))
  .refine((files: FileList) => { return  firstSizeMismatch(files) === -1; },
    (files: FileList) => ({
        message: `${firstSizeMismatch(files) > -1 ? files[firstSizeMismatch(files)].name : ""} больше
                ${MAX_FILE_SIZE / (1024 * 1024)} МБ`
    }))
const AgreeSchema = z.boolean()
    .refine((agree: boolean) => agree, "Необходимо согласиться с политикой конфиденциальности")

export const RequestSchema = z.object({
    name: NameSchema,
    phone: PhoneSchema,
    email: z.string().min(1, "Введите email")
        .email("Введите email формата example@email.com"),
    subject: z.string().min(1,"Введите тему"),
    message: z.string().min(1,"Введите сообщение").max(500, "Не более 500 символов"),
    files: FilesSchema,
    agree: AgreeSchema
})

export const VacancySchema = z.object({
    name: NameSchema,
    phone: PhoneSchema,
    username: z.string()
      .min(1, "Введите telegram username")
      .regex(/^[A-Za-z0-9_]*$/,
        "Telegram username должен содержать только цифры, латиницу и нижние подчеркивания"),
    files: FilesSchema,
    agree: AgreeSchema,
})

export const CallbackSchema = z.object({
    name: NameSchema,
    phone: PhoneSchema,
    agree: AgreeSchema,
})