import { useEffect, useState } from 'react';

export const useGetWindowWidth = () => {
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
	
	useEffect(() => {
		const handleWindowWidth = () => {
			setWindowWidth(window.innerWidth);
		};
		
		window.addEventListener('resize', handleWindowWidth);
		
		return () => {
			window.removeEventListener('resize', handleWindowWidth);
		};
	}, []);
	
	return { windowWidth };
};

export const useGetWindowHeight = () => {
	const  [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)
	
	useEffect(() => {
		const handleWindowHeight = () => {
			setWindowHeight(window.innerHeight);
		};
		
		window.addEventListener('resize', handleWindowHeight);
		
		return() => {
			window.removeEventListener('resize', handleWindowHeight);
		}
	}, [])
	
	return { windowHeight };
}