import React, { useState } from 'react'
import { useGetWindowWidth } from '../../hooks/useGetWindowSize'
import VacanciesPopup from '../popups/VacanciesPopup'
import styles from './vacancies.module.scss'
import vacancies from '../../mock/vacancies'

const Vacancies = () => {
	const [showAll, setShowAll] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)
	const [selectedVacancy, setSelectedVacancy] = useState<string | null>(null)
	
	const { windowWidth } = useGetWindowWidth()
	const toggleShowAll = () => {
		setShowAll(!showAll)
	}
	const openModal = ({ vacancyName }: { vacancyName: string }) => {
		setSelectedVacancy(vacancyName)
		setModalOpen(true)
	}
	return (
		<>
			<div className={styles.container}>
				<h2 className='block-title' id="vacancies">Вакансии</h2>
				<div className={styles.vacancies}>
					{vacancies.slice(0, showAll || windowWidth > 425 ? vacancies.length : 1).map((vacancy) => (
						<div className={styles.vacancy}>
							<p className={styles.logo}>ВР</p>
							<h3>{vacancy.name}</h3>
							<h4>{vacancy.stack}</h4>
							<button
								className='blueButton'
								onClick={() => openModal({ vacancyName: vacancy.name })}>
								Присоединиться к команде
							</button>
							<p className={styles.hashtag}>#ВебРазработка</p>
						</div>
					))}
				</div>
				<button className={`${styles.toggleButton} blueButton`} onClick={toggleShowAll}>
					{showAll ? 'Свернуть' : 'Смотреть все вакансии'}
				</button>
			</div>
			<VacanciesPopup open={modalOpen} setOpen={setModalOpen} selectedVacancy={selectedVacancy} />
		</>
	)
}

export default Vacancies