import React from "react";
import styles from "./partners.module.css"
const Partners: React.FC = () =>  {
    const logos = [
        '/Pictures/partners-logo/morion.png',
        '/Pictures/partners-logo/ujin.png',
        '/Pictures/partners-logo/promobot.png',
        '/Pictures/partners-logo/moto-crm.png',
        '/Pictures/partners-logo/RA.png',
        '/Pictures/partners-logo/medlain.png',
        '/Pictures/partners-logo/gradient.png',
        '/Pictures/partners-logo/air.png',
        '/Pictures/partners-logo/perm-krai.png',
        '/Pictures/partners-logo/pgniu.png',
        '/Pictures/partners-logo/pbi.png',
        '/Pictures/partners-logo/cdp.png',
        '/Pictures/partners-logo/fotonika.png',
        '/Pictures/partners-logo/inf-razv.png',
        '/Pictures/partners-logo/perm-politeh.png'
    ];
    return(
        <div className={styles.container}>
            <h2 className="block-title">ПАРТНЕРЫ</h2>
            <div className={styles.itemsWrap}>
                <div className={styles.items}>
                    {logos.map((logo, index) => (
                        <img src={logo} alt={""}/>
                    ))}
                </div>
                <div className={styles.items} aria-hidden="true">
                    {logos.map((logo, index) => (
                        <img src={logo} alt={""}/>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Partners