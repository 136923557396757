import { zodResolver } from '@hookform/resolvers/zod'
import styles from '../../common/popup.module.css'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import axios from 'axios'
import InputMask from 'react-input-mask'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Request } from '../../types/types'
import { FilesSchema, RequestSchema } from '../../validation/schemas'

const QuestionsPopup = (props: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>> }) => {
	const { open, setOpen } = props
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		reset,
		setError, } = useForm<Request>({
		resolver: zodResolver(RequestSchema),
		defaultValues: { phone: "" }
		})
	const token = process.env.REACT_APP_TELEGRAM_TOKEN
	const [selectedFiles, setSelectedFiles] = useState<File[]>([])
	const [active, setActive] = useState(false)
	const onSubmit: SubmitHandler<Request> = async (data: Request) => {
		try {
			await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
				chat_id: '-4227583515',
				text: `💻 Заявка на разработку:\n
				Имя: ${data.name}\n
				Телефон: ${data.phone}\n
				e-mail: ${data.email}\n
				Тема обращения: ${data.subject}\n
				Текст сообщения: ${data.message}`
			})
			
			for (let i = 0; i < selectedFiles.length; i++) {
			    const fileData = new FormData();
			    fileData.append("chat_id", "-4227583515");
			    fileData.append("document", selectedFiles[i]);
			    await axios.post(`https://api.telegram.org/bot${token}/sendDocument`, fileData);
			}
			
			reset()
			setActive(false)
			setSelectedFiles([]);
			alert('Заявка успешно отправлена!')
			handleModalClose()
		} catch (error) {
			console.error('Ошибка отправки:', error)
			alert('Ошибка отправки сообщения!')
		}
	}
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const files = Array.from(e.target.files)
			setSelectedFiles(files)
			const result = FilesSchema.safeParse(e.target.files)
			const message = result.error?.errors[0]?.message
			setError("files", {type: "invalid", message: message})
		}
	}
	const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if ((event.target as HTMLDivElement).className === styles.container) {
			handleModalClose()
		}
	}
	if (open) {
		document.body.classList.add("no-scroll")
	}
	const handleModalClose = () => {
		setOpen(false);
		document.body.classList.remove("no-scroll");
	}
	return (
		<>
			{open &&
				<div className={styles.container} onClick={handleModalClick}>
					<div className={styles.content}>
						<img src='/Pictures/popup-close.png' className={styles.close} onClick={handleModalClose}/>
						<h1>Оставьте заявку</h1>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className={`${styles.twoColumns} ${errors.name || errors.phone ? styles.errors : ''}`}>
								<div>
									<input placeholder='Как к вам обращаться?'{...register('name')} /> {errors.name &&
									<p className={styles.errorMessage}>{errors.name.message}</p>}
								</div>
								<div>
									<InputMask
										mask='+7 (999) 999-99-99' placeholder='+7 (000) 000-00-00' id='phone-number'{...register(
										'phone')} /> {errors.phone &&
									<p className={styles.errorMessage}>{errors.phone.message}</p>}
								</div>
							</div>
							<div
								className={`${styles.twoColumns} ${errors.email || errors.subject ? styles.errors : ''}`}
							>
								<div>
									<input
										placeholder='Ваша почта' type='email'{...register('email', { required: true })} /> {errors.email &&
									<p className={styles.errorMessage}>{errors.email.message}</p>}
								</div>
								<div>
									<input placeholder='Тема обращения'{...register('subject', { required: true })} /> {errors.subject &&
									<p className={styles.errorMessage}>{errors.subject.message}</p>}
								</div>
							</div>
							<div className={errors.message ? styles.errors : ''}>
              <textarea
	              className={styles.message} placeholder='Сообщение'
	              maxLength={500}{...register('message', { required: true })}>
              </textarea> {errors.message &&
								<p className={styles.errorMessage}>{errors.message.message}</p>}
							</div>
							<div className={styles.twoColumns}>
								<div className={styles.ps}>
									<h4>**О чем писать в сообщении</h4>
									<p>
										Расскажите, чем занимается ваша компания, какие задачи должен решать цифровой продукт, на какие
										сроки и бюджет рассчитываете </p>
								</div>
								<div className={styles.fileInputWrapper}>
									<input
										type='file' className={styles.fileInput} id='file-input'										{...register('files')}
										multiple onChange={handleFileChange}
									/>
									<div>
										<label htmlFor='file-input' className={styles.fileInputLabel}>
											<img src='/Pictures/papersclip.svg'/>
											<span>Прикрепить файл</span> </label>
										<div className={styles.selectedFiles}>
											{selectedFiles.map((file, index) => (
												<div key={index}>{file.name}</div>
											))}
										</div>
									</div>
									{errors.files && <span className={styles.errorMessage}>{errors.files.message}</span>}
									<p>**Максимум 3 файла (.jpg, .png, .doc, .xls, .pdf), не более 6 МБ каждый</p>
								</div>
							</div>
							<div className={styles.twoColumns}>
								<button
									type='submit' className={active && !isSubmitting ? 'blueButton' : ''}
									disabled={!active || isSubmitting}
								>{isSubmitting ? 'Загрузка...' : 'Отправить'}</button>
							<div className={styles.confidential}>
								<p>Согласен на обработку своих персональных данных в соответствии с <span
									onClick={() => {
										window.open('https://вр-ит.рф/ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf')
										}}
									>Политикой конфиденциальности</span>. </p>
									<label className={styles.fancyCheckbox}> <input
										type='checkbox' className={styles.c} {...register('agree', { required: true })} onClick={() => {
										setActive(!active)
									}}
									/>
										<div>
											<div>
												<img src='/Pictures/checkmark.png'/>
											</div>
										</div>
									</label>
								</div>
							</div>
						</form>
					</div>
				</div>
			}
		</>
	)
}

export default QuestionsPopup