import React from 'react'
import CaseComponent from "../../components/case/Case"
import Vacancies from '../../components/vacancies/Vacancies'
import cases, { Case } from "../../mock/cases"


const Debug = () => {
	return (
		<>
			<Vacancies />
			<div style={{height: "500px"}}></div>
		</>
	)
}

export default Debug