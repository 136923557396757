import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from "./servicetitle.module.css"
import { Type } from "../../../mock/services"
const content = [
	{
		id: Type.Software,
		header: (<h2 className="block-title">Разработка отечественного <span className='soft'>ПО</span></h2>),
		img: "/Pictures/services/software-gradient.svg",
		link: "/cases/gradient"
	},
	{
		id: Type.Web,
		header: (<h2 className="block-title">Разработка веб{"\u2011"}сервисов</h2>),
		img: "/Pictures/services/web-skud.svg",
		link: "/cases/skud"
	},
	{
		id: Type.Mobile,
		header: (<h2 className="block-title">Разработка мобильных приложений</h2>),
		img: "/Pictures/services/mobile-medlain.svg",
		link: "/cases/prokrasivoe"
	},
]
const ServiceTitle = (props: {type: Type}) => {
	const { type } = props;
	const navigate = useNavigate();
	const navigateTo = (where: string) => {
		navigate(where)
		window.scroll(0, 0)
	}
	return(
		<div className={styles.container}>
			{content[type].header}
			<h3>Разрабатываем сложное ПО под индивидуальные задачи бизнеса</h3>
			<div className={styles.pictureBtn}>
				<div className={styles.picture}>
					<div></div>
					<img src={content[type].img} />
				</div>
				<div
					className={styles.viewCase}
					onClick={() => {
						navigateTo(content[type].link)
					}}>
					<button className={`${styles.text} blueButton`}>
						Просмотреть кейс
					</button>
					<button className={`${styles.arrow} blueButton`}>⭢</button>
				</div>
			</div>
		</div>
	)
}

export default ServiceTitle