import styles from "./stagesdevelopment.module.css"
const StagesDevelopment = () => {
    // Массив с данными об этапах
    const stages = [
        { num: 1, name: 'Архитектура МВП-продукта', color: '#FF001F' },
        { num: 2, name: 'Разработка дизайн-макетов продукта', color: '#FF8989' },
        { num: 3, name: 'Проектирование API продукта', color: '#FFC7A7' },
        { num: 4, name: 'Бэкенд-разработка', color: '#E6FFA1' },
        { num: 5, name: 'Фронтенд-разработка', color: '#F4FF7A' },
        { num: 6, name: 'Выпуск МВП-продукта', color: '#E7FF55' },
        { num: 7, name: 'Сбор обратной связи от пользователей', color: '#BDFF69' },
        { num: 8, name: 'Внесение правок', color: '#A2FF2C' },
        { num: 9, name: 'Доработка необходимого функционала', color: '#00FF38' }
    ];

    return (
        <>
            <h2 className='block-title'>ЭТАПЫ РАЗРАБОТКИ</h2>
            <div className={styles.container}>
                {stages.map((stage, index) => (
                    // Динамический цвет рамки
                    <div key={index} style={{ border: `1px solid ${stage.color}` }}>
                        <div className={styles.num}>/{stage.num}</div>
                        <div className={styles.name}>{stage.name}</div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default StagesDevelopment;
