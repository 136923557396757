import React, { useEffect } from 'react'
import CaseComponent from '../components/case/Case'
import TitleAbout from '../components/title/TitleAbout'
import Values from '../components/values/Values'
import CompanyInfo from '../components/company-info/CompanyInfo'
import Expertise from '../components/expertise/Expertise'
import Partners from '../components/partners/Partners'
import OfficePhoto from '../components/office-photo/OfficePhoto'
import WeAreResidents from '../components/we-are-residents/WeAreResidents'
import Services from '../components/services/Services'
import Contacts from '../components/contacts/Contacts'
import Footer from '../components/footer/Footer'
import cases, { Case } from '../mock/cases'

const AboutCompany = () => {
	return (
		<>
			<TitleAbout />
			<Values />
			<CompanyInfo />
			<Expertise />
			<h2 className='block-title' id="Cases">Кейсы</h2>
			<CaseComponent _case={cases[Case.Prokrasivoe]} />
			<CaseComponent _case={cases[Case.Gradient]} />
			<CaseComponent _case={cases[Case.SKUD]} />
			<Partners />
			<OfficePhoto />
			<WeAreResidents />
			<Services />
			<Contacts />
			<Footer />
		</>
	)
}
export default AboutCompany