import CaseComponent from '../../components/case/Case'
import { useNavigate } from 'react-router-dom'
import ServiceTitle from '../../components/services-components/service-title/ServiceTitle'
import WhenNeedSoftware from '../../components/services-components/when-need/WhenNeedSoftware'
import DesiningMvp from '../../components/services-components/designing-mvp/DesiningMvp'
import ForWhatMvp from '../../components/services-components/forwhatmvp/ForWhatMvp'
import StagesDevelopment from '../../components/services-components/stagesdevelopment/StagesDevelopment'
import TechnologyStack from '../../components/services-components/technology-stack/TechnologyStack'
import React, { useEffect } from 'react'
import Principles from '../../components/services-components/principles/Principles'
import Partners from '../../components/partners/Partners'
import Awards from '../../components/services-components/awards/Awards'
import TermsSoftware from '../../components/services-components/terms-and-cost/TermsSoftware'
import PaymentFormats from '../../components/services-components/payment-formats/PaymentFormats'
import Questions from '../../components/questions/Questions'
import Services from '../../components/services/Services'
import Contacts from '../../components/contacts/Contacts'
import Footer from '../../components/footer/Footer'
import cases, { Case } from '../../mock/cases'
import { Type } from "../../mock/services"

const Software = () => {
	const navigate = useNavigate()
	return (
		<>
			<ServiceTitle type={Type.Software}/>
			<WhenNeedSoftware />
			<DesiningMvp />
			<ForWhatMvp />
			<StagesDevelopment />
			<h2 className='block-title' id='Cases'>Кейсы</h2>
			<CaseComponent _case={cases[Case.Prokrasivoe]} />
			<CaseComponent _case={cases[Case.Gradient]} />
			<CaseComponent _case={cases[Case.SKUD]} />
			<TechnologyStack />
			<Principles />
			<Partners />
			<Awards />
			<TermsSoftware />
			<PaymentFormats />
			<Questions />
			<Services />
			<Contacts />
			<Footer />
		</>
	
	
	)
}
export default Software