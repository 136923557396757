import CallbackPopup from '../popups/CallbackPopup'
import styles from './questions.module.css'
import popup from '../../common/popup.module.css'
import React, { useState } from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import axios from 'axios'
import InputMask from 'react-input-mask'
import { Request } from "../../types/types";
import { FilesSchema, RequestSchema } from '../../validation/schemas'
import {zodResolver} from "@hookform/resolvers/zod";

const Questions = () => {
	const
		{
			register,
			handleSubmit,
			formState: { errors, isSubmitting },
			reset,
			setError
		} = useForm<Request>( {
			resolver: zodResolver(RequestSchema),
			defaultValues: { phone: "" }
		})
	const [selectedFiles, setSelectedFiles] = useState<File[]>([])
	const [active, setActive] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const token = process.env.REACT_APP_TELEGRAM_TOKEN
	const onSubmit: SubmitHandler<Request> = async (data: Request) => {
		try {
			await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
				chat_id: '-4227583515',
				text: `💻 Заявка на разработку:\n
				Имя: ${data.name}\n
				Телефон: ${data.phone}\n
				e-mail: ${data.email}\n
				Тема обращения: ${data.subject}\n
				Текст сообщения: ${data.message}`
			})

			for (let i = 0; i < selectedFiles.length; i++) {
				const fileData = new FormData()
				fileData.append('chat_id', '-4227583515')
				fileData.append('document', selectedFiles[i])
				await axios.post(`https://api.telegram.org/bot${token}/sendDocument`, fileData)
			}

			reset()
			setActive(false)
			setSelectedFiles([])
			alert('Заявка успешно отправлена!')
		} catch (error) {
			console.error('Ошибка отправки:', error)
			alert('Ошибка отправки сообщения!')
		}
	}
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const files = Array.from(e.target.files)
			setSelectedFiles(files)
			const result = FilesSchema.safeParse(e.target.files)
			const message = result.error?.errors[0]?.message
			setError("files", {type: "invalid", message: message})
		}
	}
	const handleTelegramClick = () => {
		window.open('https://t.me/ooo_vebrazrabotka', '_blank')
	}
	const handlePhoneClick = () => {
		window.location.href = 'tel:+79956683584'
	}
	return (
		<>
			<div className={styles.container}>
				<h2 className='block-title' id='questions'>ОСТАЛИСЬ ВОПРОСЫ?</h2>
				<h3>Закажите <b>бесплатную</b> консультацию!</h3>
				<p className={styles.text}>
					Проконсультируем по разработке индивидуальных
					<b> веб-сервисов, мобильных приложений, ERP/CRM для вашего бизнеса</b>
					, сразу, на первой встрече.<br />Бесплатно и без обязательств.
				</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.twoColumns}>
						<div>
							<input placeholder='Как к вам обращаться?' {...register('name')} /> {errors.name &&
							<span className={styles.errorMessage}>{errors.name.message}</span>}
						</div>
						<div>
							<InputMask
								mask='+7 (999) 999-99-99'
								placeholder='+7 (000) 000-00-00'
								id='phone-number' {...register('phone')} />
							{errors.phone && <span className={styles.errorMessage}>{errors.phone.message}</span>}
						</div>
					</div>
					<div className={styles.twoColumns}>
						<div>
							<input
								placeholder='Ваша почта'
								type='email'
								{...register('email')}
							/> {errors.email && <span className={styles.errorMessage}>{errors.email.message}</span>}
						</div>
						<div>
							<input
								placeholder='Тема обращения'
								{...register('subject')}
							/> {errors.subject && <span className={styles.errorMessage}>{errors.subject.message}</span>}
						</div>
					</div>
					<textarea
						className={styles.message}
						placeholder='Сообщение'
						maxLength={500}
						{...register('message')}></textarea> {errors.message &&
					<span className={styles.errorMessage}>{errors.message.message}</span>}
					<div className={`${styles.twoColumns}`}>
						<div className={styles.ps}>
							<h4>**О чем писать в сообщении</h4>
							<p>
								Расскажите, чем занимается ваша компания, какие задачи должен решать цифровой продукт, на какие сроки и
								бюджет рассчитываете
							</p>
						</div>
						<div className={styles.fileInputWrapper}>
							<input
								type='file'
								className={styles.fileInput}
								id='file-input'
								{...register('files')}
								multiple
								onChange={handleFileChange}
							/>
							<div>
								<label htmlFor='file-input' className={styles.fileInputLabel}> <img src='/Pictures/papersclip.svg' />
									<span>Прикрепить файл</span> </label> {selectedFiles.length > 0 && (
								<div className={styles.selectedFiles}>
									{selectedFiles.map((file, index) => (
										<div key={index}>{file.name}</div>
									))}
								</div>
							)}
							</div>
							{errors.files && <span className={styles.errorMessage}>{errors.files.message}</span>}
							<p>**Максимум 3 файла (.jpg, .png, .doc, .xls, .pdf), не более 6 МБ каждый</p>
						</div>
					</div>
					<div className={styles.twoColumns}>
						<button
							type='submit'
							className={active && !isSubmitting ? "blueButton" : ''}
							disabled={!active || isSubmitting}>{isSubmitting ? "Загрузка..." : "Отправить"}</button>
						<div className={styles.confidential}>
							<p>Согласен на обработку своих персональных данных в соответствии с <span
								onClick={() => {
									window.open('https://вр-ит.рф/ПОЛИТИКА_КОНФИДЕНЦИАЛЬНОСТИ.pdf')
								}}>Политикой конфиденциальности</span>.
							</p>
							<label className={styles.fancyCheckbox}> <input
								type='checkbox'
								className={styles.c} {...register('agree', { required: true })}
								onClick={() => {setActive(!active)}}
								disabled={isSubmitting}
							/>
								<div>
									<div><img src='/Pictures/checkmark.png' /></div>
								</div>
							</label>
						</div>
					</div>
				</form>
				<h3>Вы можете задать<b> любой вопрос </b>в мессенджере или <b> по телефону</b></h3>
				<div className={styles.connection}>
					<div className={styles.tg} onClick={handleTelegramClick}>
						<span>Связаться через <b>Telegram</b></span> <img src='/Pictures/tg-icon.svg' alt='' />
					</div>
					<div className={styles.connectionPhone} onClick={() => setModalOpen(true)}>
						<p className={styles.phone}>+7 (995) 668-35-84</p>
						<p className={styles.callback}>Заказать <b>обратный звонок</b></p>
					</div>
				</div>
			</div>
			<CallbackPopup open={modalOpen} setOpen={setModalOpen} />
		</>
	)
}

export default Questions
