import styles from "./designingmvp.module.css"
import block from "../../../common/commonblock1.module.css"
const DesiningMvp = () =>{
    return(
      <div className={styles.container}>
        <h3>ПРОЕКТИРУЕМ MVP</h3>
        <h4>MVP - это минимальный жизнеспособный продукт</h4>
        <div className={block.container}>
          <img src='/Pictures/services/bell.svg' alt="" />
          <div>
            <p>Это нужно для того, чтобы быстрее и дешевле вывести продукт на рынок, получив максимально
              объективную обратную связь от пользователей</p>
          </div>
        </div>
      </div>
    )
}
export default DesiningMvp