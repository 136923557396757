import styles from "./technologystack.module.css"
const TechnologyStack = () => {
	const stack = [
		{
			id: 1, name: 'frontend', rows: [
				{ id: 1, name: 'React', logo: '/Pictures/services/stack-logos/react.svg' },
				{ id: 2, name: 'TypeScript', logo: '/Pictures/services/stack-logos/ts.svg' },
				{ id: 3, name: 'Wordpress', logo: '/Pictures/services/stack-logos/wp.svg' },
				{ id: 4, name: 'Vue', logo: '/Pictures/services/stack-logos/vue.svg' }
			]
		},
		{
			id: 2, name: 'backend', rows: [
				{ id: 1, name: 'Python', logo: '/Pictures/services/stack-logos/python.svg' },
				{ id: 2, name: 'PHP', logo: '/Pictures/services/stack-logos/php.svg' },
				{ id: 3, name: 'С# ASP.NET', logo: '/Pictures/services/stack-logos/aspnet.svg' },
				{ id: 4, name: 'PostgreSQL', logo: '/Pictures/services/stack-logos/pg.svg' },
				{ id: 5, name: 'SQL', logo: '/Pictures/services/stack-logos/sql.svg' }
			]
		},
		{
			id: 3, name: 'devops', rows: [
				{ id: 1, name: 'CI/CD', logo: '/Pictures/services/stack-logos/ci.svg' },
				{ id: 2, name: 'Docker', logo: '/Pictures/services/stack-logos/docker.svg' }
			]
		},
		{
			id: 4, name: 'ml', rows: [
				{ id: 1, name: 'Yolo Ultralytics', logo: '/Pictures/services/stack-logos/yolo.svg' },
				{ id: 2, name: 'TensorFlow', logo: '/Pictures/services/stack-logos/tensorflow.svg' }
			]
		},
	]
	return (
		<div className={styles.container}>
			<h2 className='block-title'>СТЕК ТЕХНОЛОГИЙ</h2>
			{stack.map((stack) => (
				<section>
					<h3>{stack.name}</h3>
					{stack.rows.map((row) => (
						<div className={styles.row}>
							<img src={row.logo}/>
							<div>{row.name}</div>
						</div>
					))}
				</section>
			))}
		</div>
	)
}
export default TechnologyStack