import React, {useEffect, useState} from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import styles from "./contacts.module.css"
const Contacts = () => {
    const url = 'https://yandex.ru/maps/50/perm/house/shosse_kosmonavtov_111d/YU8YdAdmQUIPQFttfXV4cHtlZA==/?ll=56.207688%2C57.990848&z=17.04';
    return (
        <>
            <h2 className="block-title" id="Contacts">КОНТАКТЫ</h2>
            <div className={styles.container}>
                <div className={styles.left}>
                    <h3><b>ООО </b><b style={{color: '#5CC3FD'}}>"ВР"</b></h3>
                    <p>Почта: <b className={styles.nowrap}>info@вр-ит.рф</b></p>
                    <p>Телефон: <b className={styles.nowrap}>+7 (995) 668-35-84</b></p>
                    <p onClick={() => {window.open(url)}} className={styles.clickable}>
                        Адрес: <b> шоссе Космонавтов, д.{'\u00A0'}111д, подъезд 3, оф. 3</b>
                    </p>
                    <p className={styles.vkLink} onClick={() => {window.open('https://vk.com/vr_it_ru')}}>Мы <b>Вконтакте</b></p>
                </div>
                <div className={styles.right}>
                    <YMaps >
                        <Map
                            defaultState={{ center: [57.991760, 56.206878], zoom: 16,  }}
                            style={{ width: '100%', height: '100%' }}
                            defaultOptions={{
                                suppressMapOpenBlock: true,
                                autoFitToViewport: 'always',
                                yandexMapDisablePoiInteractivity: true,
                            }}
                        >
                            <Placemark
                                defaultGeometry={[57.991760, 56.206878]}
                                
                            />
                        </Map>
                    </YMaps>
                </div>
            </div>
        </>
    );
};
export default Contacts;