import styles from "./expertise.module.css"
const Expertise = () => {
    return(
        <>
            <h2 className='block-title'>ЭКСПЕРТИЗА</h2>
            <div className={styles.container}>
                <div><h3>15+</h3><p>Лет опыт разработчиков</p></div>
                <div><h3>20+</h3><p>Выпущенных проектов</p></div>
                <div><h3>5</h3><p>Внутренних проектов</p></div>
            </div>
        </>
    )
}
export default Expertise