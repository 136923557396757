const services = [
    { id: 1, name: "ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ", link: '/services/software' },
    { id: 2, name: "МОБИЛЬНАЯ РАЗРАБОТКА", link: '/services/mobile-apps' },
    { id: 3, name: "РАЗРАБОТКА ВЕБ\u2011СЕРВИСОВ", link: '/services/web-services' },
];
export enum Type {
    Software,
    Web,
    Mobile,
}
export default services;